import * as firebase from 'firebase'
import withFirebaseAuth from 'react-with-firebase-auth';

const firebaseConfig = {
  apiKey: "AIzaSyBNrkxhhSw5vp2y9GSVttLGVvDkHeM3SHo",
  authDomain: "temasultera-map.firebaseapp.com",
  databaseURL: "https://temasultera-map.firebaseio.com",
  projectId: "temasultera-map",
  storageBucket: "temasultera-map.appspot.com",
  messagingSenderId: "462358138074",
  appId: "1:462358138074:web:3eddc8459297802250a9e3",
  measurementId: "G-MZHMBVM0RB"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const firebaseAppAuth = firebaseApp.auth();

/** See the signature above to find out the available providers */
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};
 
/** Create the FirebaseAuth component wrapper */
export const createComponentWithAuth = withFirebaseAuth({
  providers,
  firebaseAppAuth,
});

const DB = firebase.firestore()

export default DB
